@function px2rem($n) { 
    @return ($n/100)+rem;
}

.ac-content-one{
    .a-btn{
        text-align: center;
        .content-one-btn{
            margin: px2rem(40) auto px2rem(30);
            display: block;
            width: px2rem(450);
            height: px2rem(86);
            border-radius: px2rem(8.7);
            border: solid 1px #0a67b8;
            background-color: #1f94fa;
            padding: px2rem(20) px2rem(10);
            color: white;
            font-size: px2rem(34);
            font-weight: 500;
   }
 }
 .tips-info{
     padding : px2rem(10) px2rem(40);
     .info-content{
        line-height: px2rem(36);
        margin-bottom: px2rem(10);   
        p{  
            vertical-align: middle;
            display: inline;   
            font-size: px2rem(30);
            line-height: px2rem(44);
            &:first-line {
               margin-left: px2rem(0);
           }
        }
        .left-img{
            display: inline-block;
            margin-top: px2rem(2);
            img{
                vertical-align: top;
                width: px2rem(36);
                height: px2rem(36);
               }
           }
         .right-text{
           display: inline-block;
           vertical-align: top;
           width: 90%;
           margin-left: px2rem(15);
        //    &.text3{
        //     width: 198px;
        //    }
         }  
       }
    }
    .warning-info{
        margin : px2rem(10) px2rem(40);
        padding: px2rem(25) px2rem(30);
        border-radius: 8px;
        background-color: rgba(240, 74, 90, 0.1);
        color: #f04a5a;
        p{
            font-weight: 500;
            margin-top: px2rem(10);
            font-size: px2rem(30);
        };
    }
}