@function px2rem($n) {
  @return ($n/100) + rem;
}
body {
  max-width: 750px;
  margin: 0 auto;
  // font-family: PingFangSC-Medium;
}


.usdt-2-trc20 {
  background: #fff;
 

  .clipboard {
    display: flex;
    align-items: center;
    font-family: PingFangSC-Medium;;
  }

  .common-copy {
    line-height: 0.31rem;
    .clipboard {
      line-height: 0.31rem;
    }
  }
  .usdt-2-trc20-header {
    padding: px2rem(30) px2rem(40) px2rem(30);
    line-height: 1;
    border-bottom: px2rem(20) solid #f0f2f5;

    .ac-header-img {
      width: px2rem(90);
      height: px2rem(90);
      vertical-align: middle;
    }

    .ac-header-text {
      display: inline-block;
      vertical-align: middle;
      text-align: center;

      p {
        width: px2rem(120);
        height: px2rem(40);
        font-size: px2rem(40);
        font-weight: 600;
        margin-left: px2rem(30);
        line-height: px2rem(40);
        color: #101010;
      }
      .coin-name{
        font-family: PingFangSC-Semibold !important;
      }

      .chain-name {
        width: px2rem(110);
        height: px2rem(40);
        border-radius: px2rem(4);
        font-family: PingFangSC-Medium;;
        line-height: px2rem(40);
        border: solid px2rem(1) #27c683;
        background-color: #edfaf5;
        color: #13c781;
        box-sizing: border-box;
        font-size: px2rem(24);
        text-align: center;
        margin-top: px2rem(10);
      }
    }

    .ac-header {
      margin-bottom: px2rem(50);
      .ac-header-timer {
        font-family: PingFangSC-Medium;
        display: inline-block;
        vertical-align: middle;
        float: right;
        text-align: right;
        .timer-count {
          font-family: PingFangSC-Medium;
          color: #f04a5a;
          font-size: px2rem(36);
          font-weight: 500;
        }
        p {
          font-weight: 400;
          line-height: px2rem(48);
          font-size: px2rem(28);
          color: #354052;
        }
      }
    }

    .ac-header-num {
      margin-top: px2rem(15);
      font-weight: 500;
      height: px2rem(60);
    //   line-height: 0.6rem;
      display: flex;
      align-items: center;
      .header-label {
        display: inline-block;
        width: px2rem(140);
        color: #354052;
        font-family: PingFangSC-Medium;
        font-size: px2rem(30);
        font-weight: 500;
      }
      .item-unit{
        color: #f04a5a !important;
        margin-left:px2rem(10) ;
        margin-top: px2rem(5);
      }
      .common-red{
          color: #f04a5a !important;
          font-size: px2rem(40) !important;
      }
      .number {
        font-family: PingFangSC-Medium;
        font-size: px2rem(30) ;
        font-weight: 500;
        color: #354052;
      }
      .common-copy {
        // margin-top: px2rem(5);
        margin-left: auto;
      }
      .amount {
        height: 0.4rem;
        font-family: PingFangSC-Medium;
        line-height: 0.4rem;
        font-size: 0.45rem;
        vertical-align: baseline;
      }
    }
  }
  .nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 14px;
    .list-type {
      display: flex;
      .tab {
        position: relative;
        padding: px2rem(30) px2rem(44);
        color: #354052;
        line-height: px2rem(44);
        font-size: px2rem(30);
        cursor: pointer;
        span {
          color: #354052;
          font-family: PingFangSC-Medium;
          font-size: px2rem(34);
          font-weight: 600;
        }
        &:hover {
          color: #27c683;
          &:after {
            content: "";
            height: 2px;
            width: 80%;
            background-color: #27c683;
          }
        }
        &.active {
          span {
            color: #27c683;
          }
          &:after {
            content: "";
            height: 2px;
            width: 50%;
            background-color: #27c683;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
  .usdt-content {
    .a-btn {
      text-align: center;

      .qr-code-img {
        width: px2rem(280);
        height: px2rem(280);
        margin-top: px2rem(40);
      }

      .content-one-btn {
        width: px2rem(340);
        height: px2rem(88);
        border-radius: px2rem(8);
        color: #fff;
        line-height: px2rem(88);
        font-family: PingFangSC-Medium;
        text-align: center;
        font-size: px2rem(32);
        margin: px2rem(30) auto px2rem(40);
        background-color: #27c683;
      }
    }

    .erc20-address {
      padding: px2rem(10) px2rem(40) px2rem(20);
      line-height: 1;

      .ac-header-num {
        font-size: px2rem(33);
        font-weight: 500;
        border-top: px2rem(1) solid #e9e9e9;
        padding-top: px2rem(30);

        .number {
          width: px2rem(460);
          display: inline-block;
          word-break: normal;
          word-wrap: break-word;
          font-family: PingFangSC-Medium;
          vertical-align: middle;
          margin-left: px2rem(10);
          font-size: px2rem(30);
          font-weight: 500;
          color: #354052;
        }
      }
    }

    .warning-info {
      margin: px2rem(10) px2rem(40);
      padding: px2rem(25) px2rem(30);
      border-radius: 8px;
      background-color: rgba(240, 74, 90, 0.1);
      color: #f04a5a;

      p {
        font-weight: 500;
        font-family: PingFangSC-Medium;
        font-size: px2rem(30);
        word-break: normal;
        margin-top: px2rem(10);
        text-indent: -1em;
        margin-left: 1em;
      }
    }
  }
}
// .am-toast-text{
//     max-width: px2rem(400) ;
// }
.props-text {
  max-width: px2rem(400);
  word-wrap: break-word;
}
.model-code-img{
  width: px2rem(280);
  height: px2rem(280);
  // margin-top: px2rem(40);
}
.code-tips{
  margin-top: 10px;
  font-weight: 500;
  font-family: PingFangSC-Medium;

  font-size: px2rem(30);
  color: #f04a5a;
}
.code-preview{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5999;
  .mint-popup{
    position: fixed;
    top: 45%;
    left: 50%;
    width: px2rem(420);
    height: px2rem(420);
    transform: translate(-50%, -50%);
    // background: rgba(236, 250, 244, 1);
    margin: 0 auto;

    .model-code-img{
      width: px2rem(420);
      height: px2rem(420);
    }
    .preview-img-btn{
      width: px2rem(420);
      margin-top:px2rem(64) ;
      height: px2rem(80);
      font-size: px2rem(30);
      font-family: PingFangSC-Medium;
      background-color:#27c683;
      border-radius: px2rem(10);
      text-align: center;
      line-height: px2rem(80) ;
      color: #ffffff;
      // background-image: linear-gradient(180deg,#27c683 25%,#27c683);
    }
  }
  
}
