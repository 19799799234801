@function px2rem($n) {
    @return ($n/100)+rem;
}

.usdt-2-erc20 {
    background: #fff;
    .common-copy{
      line-height:0.31rem;
      .clipboard{
          line-height: 0.31rem;
      }   
    }
    .usdt-2-erc20-header {
        padding: px2rem(40) px2rem(40) px2rem(30);
        line-height: 1;
        border-bottom: px2rem(20) solid #f0f2f5;

        .ac-header-img {
            width: px2rem(90);
            height: px2rem(90);
            vertical-align: middle;
        }

        .ac-header-text {
            display: inline-block;
            vertical-align: middle;
            text-align: right;

            p {
                width: px2rem(110);
                height: px2rem(40);
                font-size: px2rem(48);
                font-weight: 600;
                margin-left: px2rem(30);
                line-height: px2rem(40);
                color: #101010;
            }

            .chain-name {
                width: px2rem(110);
                height: px2rem(40);
                border-radius: px2rem(4);
                border: solid px2rem(0.5) #27c683;
                background-color: #edfaf5;
                color: #13c781;
                font-size: px2rem(24);
                text-align: center;
                margin-top: px2rem(10);
            }

            ;
        }

        .ac-header {
            margin-bottom: px2rem(50);
            .ac-header-timer{
                display: inline-block;
                vertical-align: middle;
                float: right;
                text-align: right;
                .timer-count{
                    color: #f04a5a;
                    font-weight: 500;
                }
                p{  
                    font-weight: 400;
                    line-height: px2rem(48);
                    font-size: px2rem(33);
                };
            }
        }

        .ac-header-num {
            margin-top: px2rem(25);
            font-size: px2rem(33);
            font-weight: 500;
            // line-height: 1.25;
            line-height: 0.5rem;
            display: flex;
            .header-label {
                display: inline-block;
                width: px2rem(140);
            }
            .number {
                vertical-align: middle;
                margin-left: px2rem(10);
                font-size: px2rem(33);
                font-weight: 500;
            }
            .common-copy{
                margin-top: px2rem(5);
                margin-left: auto;
            }
            .amount {
                height: 0.4rem;
                line-height: 0.4rem;
                font-size: 0.45rem;
                margin-left: px2rem(15);
                vertical-align: baseline;
            }
        }

    }
    .nav {
        display: flex;
        flex: 1;
        justify-content: space-between;
        font-size: 14px;
        .list-type {
          display: flex;
          .tab {
            position: relative;
            padding: px2rem(30) px2rem(44);
            color: #354052;
            line-height: px2rem(44);
            font-size: px2rem(30);
            cursor: pointer;
            span {
              color: #354052;
              font-family: PingFangSC;
              font-size: px2rem(34);
              font-weight: 600;
            }
            &:hover {
              color: #27c683;
              &:after {
                content: "";
                height: 2px;
                width: 80%;
                background-color: #27c683;
              }
            }
            &.active {
                span{
                    color: #27c683;
                }
              &:after {
                content: "";
                height: 2px;
                width: 50%;
                background-color: #27c683;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
    }
    .usdt-content {
        .a-btn {
            text-align: center;

            .qr-code-img {
                width: px2rem(280);
                height: px2rem(280);
                margin-top: px2rem(40);
            }

            .content-one-btn {
                width: px2rem(340);
                height: px2rem(88);
                border-radius: px2rem(8);
                color: #fff;
                line-height: px2rem(88);
                text-align: center;
                font-size: px2rem(32);
                margin: px2rem(30) auto px2rem(40);
                background-color: #27c683;
            }
        }

        .erc20-address {
            padding: px2rem(20) px2rem(40) px2rem(30);
            line-height: 1;

            .ac-header-num {
                font-size: px2rem(33);
                font-weight: 500;
                line-height: 1.25;
                border-top: px2rem(1) solid #e9e9e9;
                padding-top: px2rem(30);

                .number {
                    width: px2rem(460);
                    display: inline-block;
                    word-break: normal;
                    word-wrap: break-word;
                    vertical-align: middle;
                    margin-left: px2rem(10);
                    font-size: px2rem(30);
                    font-weight: 500;
                    color: #354052;
                }
            }
        }

        .warning-info {
            margin: px2rem(10) px2rem(40);
            padding: px2rem(25) px2rem(30);
            border-radius: 8px;
            background-color: rgba(240, 74, 90, 0.1);
            color: #f04a5a;

            p {
                font-weight: 500;
                font-size: 0.3rem;
                word-break: normal;
                margin-top: px2rem(10);
                text-indent: -1em;
                margin-left: 1em;
            }

            ;
        }
    }
}