@function px2rem($n) {
    @return ($n/100)+rem;
}
:global{
    .am-modal-wrap {
        .am-modal-alert-content{
          font-family: PingFangSC;
          font-size: px2rem(28);
          color: #333333;
        }
      }
}
.bottom-content-wrap {
    padding-bottom: px2rem(120);
}
.bank-pay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    .big-img{
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 1);
        img{
            width: 100%;
            margin-top: 35%;
        }
    }
    .common-copy{
        line-height:px2rem(30);
        margin-top: px2rem(4);
        .clipboard{
            line-height: 1;
        }   
      }
    .tab-wrap {
        padding: px2rem(30) px2rem(30) px2rem(30) px2rem(10);
        border-bottom: px2rem(1) solid #e9e9e9;
        background: #fff;
        li {
            display: inline-block;
            padding: px2rem(16) px2rem(25);
            border-radius: px2rem(30);
            background-color: #f2f5fa;
            height: px2rem(60);
            font-size: px2rem(30);
            font-weight: 500;
            text-align: center;
            line-height: 0.3rem;
            color: #354052;
            margin-left: px2rem(20);
            .recommend{
                font-size: px2rem(20);
            }
            &.active {
                color: #ffffff;
                background-color: #27c683;
            }
        }
    }
    .bottom-btn{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: px2rem(110);
        background-color: #27c683;
        color: #fff;
        text-align: center;
        line-height: px2rem(110);
        font-size: px2rem(34);
    }
}