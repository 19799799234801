@function px2rem($n) {
  @return ($n/100) + rem;
}
@keyframes myMove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.relay-page {
  background: #fff;
  .err-page {
    margin: px2rem(30) px2rem(40);
    border-radius: px2rem(20);
    height: px2rem(750);
    background: #f6f6f6;
    overflow: hidden;
    .waring-btn {
      width: px2rem(110);
      height: px2rem(110);
      margin: px2rem(240) auto px2rem(40);
      img {
        width: 100%;
      }
    }
    p {
      margin: px2rem(52) auto;
      color: #333333;
      font-size: px2rem(32);
      font-weight: 500;
      text-align: center;
    }
  }
  .top {
    height: px2rem(20);
    background-color: #f0f2f5;
  }
  .tip {
    margin: px2rem(20) 0;
    .title {
      padding: px2rem(10) px2rem(75);
      font-size: px2rem(32);
      font-weight: 500;
      color: #354052;
      .amout {
        color: red;
      }
      .relay-copy {
        background-color: #44d09a;
        button {
          color: #ffffff;
          line-height: 1;
        }
      }
    }
  }
  .conotent {
    text-align: center;
    height: px2rem(750);
    background-image: linear-gradient(to bottom, #37e0ae, #19b070 182%);
    margin: 0 px2rem(40);
    border-radius: px2rem(20);
    .content-img {
      text-align: center;
      position: relative;
      .loading-img {
        animation: myMove 3s infinite linear;
        width: px2rem(126);
        margin-top: px2rem(160);
        margin-bottom: px2rem(80);
      }
      .count-num {
        width: px2rem(20);
        text-align: center;
        color: #ffffff;
        font-size: px2rem(44);
        font-weight: 600;
        height: px2rem(60);
        position: absolute;
        bottom: px2rem(108);
        left: px2rem(300);
        &.little-num {
          left: px2rem(320);
        }
        &.three-len {
          left: px2rem(300);
        }
        &.two-len {
          left: px2rem(310);
        }
        &.one-len {
          left: px2rem(320);
        }
      }
    }
    .content-title {
      font-size: px2rem(40);
      font-weight: 500;
      color: #ffffff;
    }
    .msg {
      margin-top: px2rem(20);
      height: px2rem(40);
      color: #ffffff;
      font-size: px2rem(28);
    }
    .btn {
      position: relative;
      .btn-content {
        text-align: center;
        width: px2rem(400);
        height: px2rem(74);
        opacity: 0.3;
        border-radius: px2rem(37);
        background-color: #ffffff;
        color: #fff;
        margin: px2rem(40) auto;
      }
      .btn-text {
        position: absolute;
        top: px2rem(17);
        left: px2rem(250);
        font-size: px2rem(28);
        margin: 0 auto;
        color: #fff;
      }
    }
  }
  .btm {
    height: px2rem(40);
    color: #404553;
    font-weight: 500;
    position: fixed;
    bottom: px2rem(50);
    left: px2rem(265);
  }
}
