
@function px2rem($n) { 
    @return ($n/100)+rem;
}

.auto-callback-header{
    background: white;
    padding: px2rem(40) px2rem(40) px2rem(30);
    line-height: 1;
    border-bottom: px2rem(20) solid #f0f2f5;
    .ac-header-img{
        width: px2rem(260);
        height: px2rem(92);
        vertical-align: middle;
    }
    .ac-header-timer{
        display: inline-block;
        vertical-align: middle;
        float: right;
        text-align: right;
        .timer-count{
            color: #f04a5a;
            font-weight: 500;
        }
        p{  
            font-weight: 400;
            line-height: px2rem(48);
            font-size: px2rem(33);
        };
    }  
    .ac-header{
        margin-bottom: px2rem(50);
    }
    .ac-header-num{
        margin-top: px2rem(25);
        font-size: px2rem(33);
        font-weight: 500;
        line-height: 1.25;
        .ac-header-lable{
            display: inline-block;
            margin-top: px2rem(5);
        }
        .number{
            vertical-align:middle;
            margin-left: px2rem(30);
            font-size: px2rem(33);
            font-weight: 500;
        }
        .amount{
            height: 0.4rem;
            line-height: 0.4rem;
            font-size: 0.45rem;
            vertical-align: baseline;
            &.tips{
                font-size: 12px;
                height: 0.2rem;
                margin-left: px2rem(162);
            }
        }
    }
}