@function px2rem($n) {
  @return ($n/100) + rem;
}
@keyframes myMove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.vietnam-pay {
  .top-text{
    display: inline-block;
    width: 34%;
  }
  .ac-header-img {
    width: px2rem(260);
    height: px2rem(92);
    vertical-align: middle;
  }
  .ac-header-timer {
    padding: px2rem(40) px2rem(20) 0 0;
    display: inline-block;
    vertical-align: middle;
    float: right;
    text-align: right;
    .timer-count {
      color: #f04a5a;
      font-weight: 500;
    }
    p {
      font-weight: 400;
      line-height: px2rem(48);
      font-size: px2rem(33);
    }
  }
  .ac-header {
    display: inline;
    margin-bottom: px2rem(50);
  }
  .common-copy {
    cursor: pointer;
    margin-left: px2rem(10);
    vertical-align: middle;
    width: px2rem(100);
    height: px2rem(40);
    font-size: px2rem(26);
    padding: px2rem(4) px2rem(19);
    border-radius: px2rem(22);
    border: solid px2rem(1) rgba(39, 198, 131, 0.8);
    background-color: #edfaf5;
    color: #27c683;
    float: right;
    text-align: center;
    line-height: 1rem;
    line-height: 0.3rem;
    &.V2 {
      width: px2rem(156);
    }
  }
  .scrollbar-default-show {
    ::-webkit-scrollbar {
      visibility: visible;
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #7f7f7f;
      border-radius: 4px;
      visibility: visible;
    }
  }
  .top-content {
    // margin: px2rem(20);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: white;
    .top-label {
      margin: px2rem(20);
      font-size: px2rem(40);
      font-weight: 500;
      color: #354052;
      margin-bottom: px2rem(20);
      padding: px2rem(20) 0;
    }
    .tab-wrap {
      margin: px2rem(20);
      margin-bottom: px2rem(20);
      li {
        font-size: px2rem(30);
        padding: px2rem(10) px2rem(15);
        margin: 0;
        list-style: none;
        color: black;
        .red {
          color: red;
        }
      }
    }
    .title-label {
      margin: px2rem(20);
      font-size: 0.4rem;
      font-weight: 500;
      color: #354052;
      padding: 0.2rem 0 0;
    }
  }
  .line {
    height: px2rem(20);
    background-color: #f0f2f5;
  }
  .bottom-content {
    margin-top: px2rem(410);
    padding: px2rem(40) px2rem(20);
    p {
      font-size: px2rem(40);
      font-weight: 500;
      color: #354052;
      margin-bottom: px2rem(30);
    }

    .am-list {
      .am-checkbox-checked {
        .check-btn {
          position: absolute;
          display: none;
          top: px2rem(1.5);
          right: px2rem(6);
          z-index: 999;
          width: px2rem(5);
          height: px2rem(11);
          border-style: solid;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
          &.check-icon {
            display: block;
          }
        }
      }
      .am-list-body {
        // max-height: 400px;
        overflow: auto;
        .am-radio-inner:after {
          content: "◉";
          color: #108ee9;
          transform: rotate(0deg);
          font-size: 18px;
          top: px2rem(-3.5);
          border-style: none;
        }
      }
    }
  }
  .bottom-btn {
    position: fixed;
    left: 0;
    z-index: 999;
    bottom: 0;
    width: 100%;
    height: px2rem(110);
    background-color: #27c683;
    color: #fff;
    text-align: center;
    line-height: px2rem(110);
    font-size: px2rem(34);
    &.V2 {
      background-color: #909399;
    }
  }
}
