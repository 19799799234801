@function px2rem($n) { 
    @return ($n/100)+rem;
}

.time-out{
    // margin-top: px2rem(500);
 .waring-btn{
    width: px2rem(110);
    height: px2rem(110);
    margin: px2rem(76) auto px2rem(40);
    img{
        width: 100%;
    }
 }
    .time-out-info{
        text-align: center;
        padding: 0 px2rem(100);
        div{
            font-size: px2rem(36);
            font-weight: 500;
            color: #333333;
            margin-bottom: px2rem(20);
        }
        p{
            font-size: px2rem(32);
            font-weight: 500;
            color: #f04a5a;
        }
    }
}