@function px2rem($n) { 
    @return ($n/100)+rem;
}

.ac-content-three{
    margin-top: px2rem(40);
    .a-btn{
        width: px2rem(670);
        height: px2rem(300);
        border-radius: px2rem(8);
        background-color: #f2f5fa;
        padding: px2rem(30) px2rem(10) px2rem(30) px2rem(20);
        margin: px2rem(40);
        p{
            font-size: px2rem(30);
            font-weight: 500;
            line-height: 2;
            color: #354052;
            white-space: nowrap;
            .label{
                display: inline-block;
                width: px2rem(150);
                margin-right: px2rem(30);
            }
            .content{
                margin-right: px2rem(10);
            }
            .common-copy{
                margin-top: px2rem(10);
            }
        };
 }
 .tips-info{
     padding : px2rem(10) px2rem(40);
     .info-content{
         line-height: px2rem(36);
         margin-bottom: px2rem(10);   
         p{  
             vertical-align: middle;
             display: inline;   
             font-size: px2rem(30);
             line-height: px2rem(44);
             &:first-line {
                margin-left: px2rem(0);
            }
         }
         .left-img{
             display: inline-block;
             margin-top: px2rem(2);
             img{
                 vertical-align: top;
                 width: px2rem(36);
                 height: px2rem(36);
                }
            }
          .right-text{
            display: inline-block;
            width: 90%;
            vertical-align: top;
            margin-left: px2rem(20);
          }  
        }
    }
    .warning-info{
        margin : px2rem(10) px2rem(40);
        padding: px2rem(25) px2rem(30);
        border-radius: 8px;
        background-color: rgba(240, 74, 90, 0.1);
        color: #f04a5a;
        p{
            font-weight: 500;
            margin-top: px2rem(10);
            font-size: px2rem(30);
        };
    }
}