@function px2rem($n) { 
    @return ($n/100)+rem;
}

.ac-content-second{
    padding: px2rem(10) px2rem(10) px2rem(30) px2rem(40);
    background: white;
 .qr-img{
     display: inline-block;
     width: px2rem(200);
     img{
        width: px2rem(200);
        height: px2rem(200);
     }
     .save-img{
        cursor: pointer;
        margin-top: px2rem(25);
        width: px2rem(200);
        height: px2rem(60);
        border-radius: px2rem(44);
        background-color: #f2f5fa;
        text-align: center;
        line-height: px2rem(40);
        font-weight: 500;
        color: #354052;
        font-size: px2rem(30);
        padding: px2rem(10) px2rem(15);
     }
 }
 .tips-info{
     display: inline-block;
     width: px2rem(412);
     margin-left: px2rem(30);
    //  padding : px2rem(10) px2rem(40);
     text-align: left;
     .info-content{
        line-height: px2rem(36);
        margin-bottom: px2rem(10);   
        p{  
            vertical-align: middle;
            display: inline;   
            font-size: px2rem(30);
            line-height: px2rem(44);
            &:first-line {
               margin-left: px2rem(0);
           }
        }
        .left-img{
            display: inline-block;
            margin-top: px2rem(2);
            img{
                vertical-align: top;
                width: px2rem(36);
                height: px2rem(36);
               }
           }
         .right-text{
           display: inline-block;
           vertical-align: top;
           margin-left: px2rem(15);
           &.text3{
            width: px2rem(350);
           }
         }  
       }
    }
    .warning-info{
        margin : px2rem(30) px2rem(40) px2rem(30) px2rem(0);
        padding: px2rem(25) px2rem(30);
        border-radius: 8px;
        background-color: rgba(240, 74, 90, 0.1);
        color: #f04a5a;
        p{
            font-weight: 500;
            margin-top: px2rem(10);
            font-size: px2rem(30);
        };
    }

    .big-img{
        z-index: 999;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 1);
        img{
            width: 100%;
            margin-top: 35%;
        }
    }
}